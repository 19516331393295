import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router';

import { getUser, User } from './services/authService';
import LoginPage from './pages/LoginPage';
import Loader from './pages/sharedcomponents/Loader';
import TextSystemPage from './pages/textsystem/TextSystemPage';
import FileBasedSystemPage from './pages/filebasedsystem/FileBasedSystemPage';
import HomePage from './pages/HomePage';

function App() {
  // TODO: Is there a generic way for loading states while fetching data?
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    loadUser().then(() => {});
  }, []);

  const loadUser = async () => {
    setLoading(true);
    const user = await getUser();
    setUser(user);
    setLoading(false);
  };

  if (loading)
    return (
      <div className="p-16">
        <Loader />
      </div>
    );

  if (!user) return <LoginPage onLoginHook={loadUser} />;

  return (
    <Router>
      <Routes>
        <Route index element={<HomePage />} />
        <Route path={'/text'} element={<TextSystemPage />} />
        <Route path={'/file'} element={<FileBasedSystemPage />} />
      </Routes>
    </Router>
  );
}

export default App;
