import { useState } from 'react';

import DisplayPanelRoot from '../sharedcomponents/displaypanel/DisplayPanelRoot';
import UserPromptInput from '../sharedcomponents/UserPromptInput';
import {
  generateFileBasedPromptResponse,
  ModelTag,
} from '../../services/genaiService';
import ModelTagSelectBox from '../sharedcomponents/ModelTagSelectBox';
import SubmitPromptButton from '../sharedcomponents/SubmitPromptButton';
import FileUpload from './FileUpload';

export default function FileBasedSystemPage() {
  const [userPrompt, setUserPrompt] = useState('');
  const [output, setOutput] = useState('');
  const [modelInstance, setModelInstance] = useState(ModelTag.GEMINI_PRO);
  const [file, setFile] = useState<File | null>(null);
  const [contextFiles, setContextFiles] = useState<File[]>([]);

  const handleSubmit = () => {
    if (!file) {
      setOutput('No file selected');
      return;
    }

    const LOADING_PLACEHOLDER = '...';
    setOutput(LOADING_PLACEHOLDER);
    generateFileBasedPromptResponse(
      modelInstance,
      file,
      contextFiles,
      userPrompt,
      (chunk: string) => {
        setOutput(response => {
          if (response === LOADING_PLACEHOLDER) {
            return chunk;
          }
          return response + chunk;
        });
      },
      (error: Error) => {
        setOutput(error.message);
      },
    );
  };

  const handleFilesSelect = (file: File, contextFiles: File[]) => {
    setFile(file);
    setContextFiles(contextFiles);
  };

  return (
    <div className="h-screen">
      <div className="h-2/3 p-2">
        <DisplayPanelRoot content={output} />
      </div>
      <div className="flex h-1/3 flex-col">
        <div className="flex items-center justify-between p-2">
          <ModelTagSelectBox
            modelInstance={modelInstance}
            handleModelInstanceChange={setModelInstance}
          />
          <SubmitPromptButton onSubmit={handleSubmit} />
        </div>
        <div className="flex flex-grow gap-2 overflow-auto p-2">
          <div className="w-1/4">
            <FileUpload onFilesSelect={handleFilesSelect} />
          </div>
          <div className="w-3/4">
            <UserPromptInput
              prompt={userPrompt}
              handlePromptChange={value => setUserPrompt(value)}
              onSubmit={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
