import React, { useCallback } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';

interface GoogleSearchButtonProps {
  userPrompt: string;
}

export default function GoogleSearchButton({
  userPrompt,
}: GoogleSearchButtonProps) {
  const searchWithGoogle = useCallback(() => {
    const url = `https://www.google.com/search?q=${encodeURIComponent(userPrompt)}`;
    window.open(url, '_blank');
  }, [userPrompt]);

  return (
    <button
      tabIndex={1}
      onClick={searchWithGoogle}
      className="neu-up rounded-full p-2"
    >
      <MagnifyingGlassIcon className="size-4" />
    </button>
  );
}
