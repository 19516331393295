import { PaperAirplaneIcon } from '@heroicons/react/24/solid';

interface SubmitPromptButtonProps {
  onSubmit: () => void;
}

export default function SubmitPromptButton({
  onSubmit,
}: SubmitPromptButtonProps) {
  return (
    <button tabIndex={1} onClick={onSubmit} className="neu-up rounded-full p-2">
      <PaperAirplaneIcon className="size-4" />
    </button>
  );
}
