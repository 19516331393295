import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import md5 from 'md5';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { ClipboardDocumentIcon } from '@heroicons/react/24/outline';

interface MarkdownDisplayPanelProps {
  content: string;
}

export default function MarkdownDisplayPanel({
  content,
}: MarkdownDisplayPanelProps) {
  const handleCopy = async (code: string) => {
    await navigator.clipboard.writeText(code);
  };

  return (
    <ReactMarkdown
      key={md5(content)}
      className="prose prose-invert max-w-none"
      remarkPlugins={[remarkGfm]}
      components={{
        code(props) {
          const { children, className, node, ...rest } = props;
          const match = /language-(\w+)/.exec(className || '');
          const code = String(children);

          return match ? (
            <div className="relative">
              <button
                onClick={() => handleCopy(code)}
                className="neu-up absolute right-1 top-1 rounded-lg p-2"
              >
                <ClipboardDocumentIcon className={`size-4`} />
              </button>
              <SyntaxHighlighter
                language={match[1]}
                children={code}
                style={vscDarkPlus}
              />
            </div>
          ) : (
            <code {...rest} className={className}>
              {children}
            </code>
          );
        },
      }}
    >
      {content}
    </ReactMarkdown>
  );
}
