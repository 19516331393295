import { ModelTag } from '../../services/genaiService';

interface ModelInstanceSelectBoxProps {
  modelInstance: ModelTag;
  handleModelInstanceChange: (modelInstance: ModelTag) => void;
}

export default function ModelTagSelectBox({
  modelInstance,
  handleModelInstanceChange,
}: ModelInstanceSelectBoxProps) {
  return (
    <select
      tabIndex={1}
      value={modelInstance}
      onChange={e => handleModelInstanceChange(e.target.value as ModelTag)}
      className="neu-down appearance-none px-2 py-1"
    >
      <option value={ModelTag.GEMINI_PRO}>Gemini Pro</option>
      <option value={ModelTag.GEMINI_FLASH_THINKING}>
        Gemini Flash Thinking
      </option>
      <option value={ModelTag.GEMINI_FLASH}>Gemini Flash</option>
      <option value={ModelTag.CLAUDE_SONNET}>Claude Sonnet</option>
      <option value={ModelTag.CLAUDE_HAIKU}>Claude Haiku</option>
    </select>
  );
}
