import { axiosInstance } from './axiosInstance';

export enum ModelTag {
  GEMINI_FLASH = 'GEMINI_FLASH',
  GEMINI_FLASH_THINKING = 'GEMINI_FLASH_THINKING',
  GEMINI_PRO = 'GEMINI_PRO',
  CLAUDE_SONNET = 'CLAUDE_SONNET',
  CLAUDE_HAIKU = 'CLAUDE_HAIKU',
}

async function parseResponseStream(
  stream: ReadableStream,
  callback: (chunk: string) => void,
) {
  const reader = stream.pipeThrough(new TextDecoderStream()).getReader();
  while (true) {
    const { value, done } = await reader.read();
    if (done) break;
    const regex = /^data: (.*)\n\n$/;
    const match = value.match(regex);
    if (match) {
      const chunk = match[1];
      const unescaped_chunk = chunk.replaceAll('\\n', '\n');
      callback(unescaped_chunk);
    }
  }
}

export const generateTextBasedPromptResponse = (
  model: string,
  prompt: string,
  callback: (chunk: string) => void,
  err: (err: Error) => void,
) => {
  axiosInstance
    .post(
      '/text/stream',
      {
        model: model,
        prompt: prompt,
      },
      {
        responseType: 'stream',
      },
    )
    .then(async response => {
      await parseResponseStream(response.data, callback);
    })
    .catch(error => {
      err(error);
    });
};

export const generateFileBasedPromptResponse = (
  modelInstance: string,
  file: File,
  contextFiles: File[],
  prompt: string,
  callback: (chunk: string) => void,
  err: (err: Error) => void,
) => {
  const formData = new FormData();
  formData.append('model', modelInstance);
  formData.append('prompt', prompt);
  formData.append('file', file);
  for (const contextFile of contextFiles) {
    formData.append('context_file', contextFile);
  }

  axiosInstance
    .post('/file/stream', formData, {
      responseType: 'stream',
    })
    .then(({ data }) => parseResponseStream(data, callback))
    .catch(error => {
      err(error);
    });
};
