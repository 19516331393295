import React from 'react';
import { NavLink } from 'react-router';

export default function HomePage() {
  return (
    <div className={'flex flex-col items-center p-8'}>
      <NavLink to={'/text'}>Text</NavLink>
      <NavLink to={'/file'}>File</NavLink>
    </div>
  );
}
