import React, { KeyboardEvent } from 'react';

interface UserPromptInputProps {
  prompt: string;
  handlePromptChange: (value: string) => void;
  onSubmit: () => void;
}

export default function UserPromptInput({
  prompt,
  handlePromptChange,
  onSubmit,
}: UserPromptInputProps) {
  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
      event.preventDefault();
      onSubmit();
    }
  };

  return (
    <textarea
      value={prompt}
      onChange={event => handlePromptChange(event.target.value)}
      onKeyDown={handleKeyDown}
      autoFocus
      autoCapitalize="off"
      autoCorrect="off"
      spellCheck="false"
      className="neu-down h-full w-full resize-none p-2"
    />
  );
}
