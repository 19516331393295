import { useState } from 'react';

import DisplayPanelRoot from '../sharedcomponents/displaypanel/DisplayPanelRoot';
import UserPromptInput from '../sharedcomponents/UserPromptInput';
import {
  generateTextBasedPromptResponse,
  ModelTag,
} from '../../services/genaiService';
import GoogleSearchButton from './GoogleSearchButton';
import ModelTagSelectBox from '../sharedcomponents/ModelTagSelectBox';
import SubmitPromptButton from '../sharedcomponents/SubmitPromptButton';

export default function TextSystemPage() {
  const [userPrompt, setUserPrompt] = useState('');
  const [output, setOutput] = useState('');
  const [modelInstance, setModelInstance] = useState(ModelTag.GEMINI_PRO);

  const handleSubmit = () => {
    const LOADING_PLACEHOLDER = '...';
    setOutput(LOADING_PLACEHOLDER);
    generateTextBasedPromptResponse(
      modelInstance,
      userPrompt,
      (chunk: string) => {
        setOutput(response => {
          if (response === LOADING_PLACEHOLDER) {
            return chunk;
          }
          return response + chunk;
        });
      },
      (error: Error) => {
        setOutput(error.message);
      },
    );
  };

  return (
    <div className="h-screen">
      <div className="h-2/3 p-2">
        <DisplayPanelRoot content={output} />
      </div>
      <div className="flex h-1/3 flex-col gap-2 p-2">
        <div className="flex items-center justify-between">
          <ModelTagSelectBox
            modelInstance={modelInstance}
            handleModelInstanceChange={setModelInstance}
          />
          <div className="flex gap-2">
            <GoogleSearchButton userPrompt={userPrompt} />
            <SubmitPromptButton onSubmit={handleSubmit} />
          </div>
        </div>
        <div className="flex-grow">
          <UserPromptInput
            prompt={userPrompt}
            handlePromptChange={value => setUserPrompt(value)}
            onSubmit={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
}
