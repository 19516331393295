import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

interface FileUploadProps {
  onFilesSelect: (file: File, contextFiles: File[]) => void;
}

export default function FileUpload({ onFilesSelect }: FileUploadProps) {
  const [primaryFile, setPrimaryFile] = useState<File | null>(null);
  const [contextFiles, setContextFiles] = useState<File[]>([]);

  const handleFiles = useCallback(
    (files: File[]) => {
      if (files.length === 0) {
        return;
      }

      const [primaryFile, ...contextFiles] = files;

      setPrimaryFile(primaryFile);
      setContextFiles(contextFiles);

      onFilesSelect(primaryFile, contextFiles);
    },
    [onFilesSelect],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFiles,
    noClick: true,
  });

  const handlePrimaryFileChange = (file: File) => {
    const newContextFiles = contextFiles.filter(
      contextFile => contextFile !== file,
    );
    newContextFiles.unshift(primaryFile!);

    setContextFiles(newContextFiles);
    setPrimaryFile(file);

    onFilesSelect(file, newContextFiles);
  };

  const handlePaste = (event: React.ClipboardEvent) => {
    handleFiles(Array.from(event.clipboardData.files));
  };

  return (
    <div
      {...getRootProps()}
      onPaste={handlePaste}
      className={'neu-down h-full overflow-auto p-2'}
    >
      <input type="file" multiple {...getInputProps()} />
      {primaryFile === null ? (
        <p>Drop files</p>
      ) : (
        <div className={'flex flex-col gap-1'}>
          {/*TODO: Modularize the File name display line */}
          <div
            className={'overflow-hidden overflow-ellipsis whitespace-nowrap'}
          >
            {primaryFile.name}
          </div>
          {contextFiles.map(file => (
            <div
              key={file.name}
              onClick={() => handlePrimaryFileChange(file)}
              className={
                'overflow-hidden overflow-ellipsis whitespace-nowrap hover:cursor-pointer'
              }
            >
              <p>{file.name}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
