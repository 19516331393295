import { KeyboardEvent, useCallback, useState } from 'react';
import RawDisplayPanel from './RawDisplayPanel';
import MarkdownDisplayPanel from './MarkdownDisplayPanel';

interface DisplayPanelRootProps {
  content: string;
}

export default function DisplayPanelRoot({ content }: DisplayPanelRootProps) {
  const [rawDisplay, setRawDisplay] = useState(false);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.ctrlKey && event.key === 'f') {
        setRawDisplay(prevRawDisplay => !prevRawDisplay);
      }
    },
    [setRawDisplay],
  );

  return (
    <div
      className={'neu-up h-full w-full overflow-x-scroll p-4'}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      {rawDisplay ? (
        <RawDisplayPanel content={content} />
      ) : (
        <MarkdownDisplayPanel content={content} />
      )}
    </div>
  );
}
